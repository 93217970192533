import { detectDevice, detectOperatingSystem } from '@/utils/checkForOperatingSystem';
import { detectBrowser } from '@/utils/checkForBrowser';

const deviceOptions = [
  {
    label: 'Desktop/Laptop',
    value: 'desktop',
  },
  {
    label: 'Tablet/Mobile',
    value: 'mobile',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

const browserOptions = [
  {
    label: 'Google Chrome',
    value: 'chrome',
  },
  {
    label: 'Firefox',
    value: 'firefox',
  },
  {
    label: 'Safari',
    value: 'safari',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

const operatingSystemOptions = [
  {
    label: 'Windows',
    value: 'microsoft',
  },
  {
    label: 'MacOS',
    value: 'mac',
  },
  {
    label: 'Linux',
    value: 'linux',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

const formFields = [
  {
    id: 'emailAddress',
    label: 'Email',
    type: 'email',
    required: true,
    helpText: 'Please enter a valid email address',
    placeHolder: 'i.e. john.doe@example.com',
    validationMessage: 'Please enter a valid Email Address',
  },
  {
    id: 'fullName',
    label: 'Full Name',
    type: 'string',
    required: true,
    helpText: 'Please enter a Full Name',
    placeHolder: 'i.e. John Doe',
    validationMessage: 'Please enter a valid Name',
    pattern: /[a-zA-Z0-9 -]/g,
  },
  {
    id: 'device',
    label: 'Device',
    type: 'dropdown',
    required: true,
    options: deviceOptions,
    helpText: 'Please select a Device',
    placeHolder: 'Select a device...',
  },
  {
    id: 'browser',
    label: 'Browser',
    type: 'dropdown',
    required: true,
    options: browserOptions,
    helpText: 'Please select a browser',
    placeHolder: 'Select a browser...',
  },
  {
    id: 'operatingSystem',
    label: 'OS',
    type: 'dropdown',
    required: true,
    options: operatingSystemOptions,
    helpText: 'Please select an Operating System',
    placeHolder: 'Select an OS...',
  },
];

const initialValues = {
  device: detectDevice(),
  browser: detectBrowser(),
  operatingSystem: detectOperatingSystem(),
};

const technicalSupportData = {
  formFields,
  deviceOptions,
  browserOptions,
  operatingSystemOptions,
  initialValues,
};

export default technicalSupportData;
